import { Calendar, People, Buy } from "react-iconly";
import IntlMessages from "../../layout/components/lang/IntlMessages";

const apps = [
    {
        id: "apps",
        header: <IntlMessages id="sidebar-apps" />,
        subMenu: [
            {
                id: "apps-calendar",
                title: <IntlMessages id="sidebar-apps-calendar" />,
                icon: <Calendar set="curved" />,
                navLink: "/apps/calendar",
            },
            {
                id: "contact",
                title: <IntlMessages id="sidebar-apps-contact" />,
                icon: <People set="curved" />,
                navLink: "/apps/contact",
            },
            {
                id: "ecommerce",
                title: <IntlMessages id="sidebar-apps-ecommerce" />,
                icon: <Buy set="curved" />,
                children: [
                    {
                        id: "shop",
                        title: <IntlMessages id="sidebar-apps-ecommerce-shop" />,
                        navLink: "/apps/ecommerce/shop",
                    },
                    {
                        id: "wishlist",
                        title: <IntlMessages id="sidebar-apps-ecommerce-wishlist" />,
                        navLink: "/apps/ecommerce/wishlist",
                    },
                    {
                        id: "product-detail",
                        title: <IntlMessages id="sidebar-apps-ecommerce-product-detail" />,
                        navLink: "/apps/ecommerce/product-detail/0",
                    },
                    {
                        id: "checkout",
                        title: <IntlMessages id="sidebar-apps-ecommerce-checkout" />,
                        navLink: "/apps/ecommerce/checkout",
                    },
                    {
                        id: "inventory",
                        title: <IntlMessages id="sidebar-apps-ecommerce-inventory" />,
                        navLink: "/apps/ecommerce/inventory",
                    },
                ],
            },
        ]
    },
];

export default apps