import { Filter2, TicketStar, Discovery, Category, Danger } from "react-iconly";
import IntlMessages from "../../layout/components/lang/IntlMessages";

const components = [
    {
        id: "components",
        header: <IntlMessages id="sidebar-components" />,
        subMenu: [
            {
                id: "general",
                title: <IntlMessages id="sidebar-components-general" />,
                icon: <Category set="curved" className="remix-icon" />,
                children: [
                    {
                        id: "style-guide",
                        title: <IntlMessages id="sidebar-components-style-guide" />,
                        navLink: "/components/general/style-guide",
                    },
                    {
                        id: "buttons",
                        title: <IntlMessages id="sidebar-components-buttons" />,
                        navLink: "/components/general/buttons",
                    },
                    {
                        id: "icons",
                        title: <IntlMessages id="sidebar-components-icons" />,
                        navLink: "/components/general/icons",
                    },
                ],
            },
            {
                id: "navigation",
                title: <IntlMessages id="sidebar-components-navigation" />,
                icon: <Discovery set="curved" className="remix-icon" />,
                children: [
                    {
                        id: "breadcrumb",
                        title: <IntlMessages id="sidebar-components-breadcrumbs" />,
                        navLink: "/components/navigation/breadcrumb",
                    },
                    {
                        id: "dropdown",
                        title: <IntlMessages id="sidebar-components-dropdown" />,
                        navLink: "/components/navigation/dropdown",
                    },
                    {
                        id: "menu",
                        title: <IntlMessages id="sidebar-components-menu" />,
                        navLink: "/components/navigation/menu",
                    },
                    {
                        id: "pagination",
                        title: <IntlMessages id="sidebar-components-pagination" />,
                        navLink: "/components/navigation/pagination",
                    },
                    {
                        id: "steps",
                        title: <IntlMessages id="sidebar-components-steps" />,
                        navLink: "/components/navigation/steps",
                    },
                ],
            },
            {
                id: "data-entry",
                title: <IntlMessages id="sidebar-components-data-entry" />,
                icon: <Filter2 set="curved" className="remix-icon" />,
                children: [
                    {
                        id: "checkbox",
                        title: <IntlMessages id="sidebar-components-checkbox" />,
                        navLink: "/components/data-entry/checkbox",
                    },
                    {
                        id: "datepicker",
                        title: <IntlMessages id="sidebar-components-datepicker" />,
                        navLink: "/components/data-entry/datepicker",
                    },
                    {
                        id: "form",
                        title: <IntlMessages id="sidebar-components-form" />,
                        navLink: "/components/data-entry/form",
                    },
                    {
                        id: "inputs",
                        title: <IntlMessages id="sidebar-components-inputs" />,
                        navLink: "/components/data-entry/inputs",
                    },
                    {
                        id: "input-number",
                        title: <IntlMessages id="sidebar-components-inputnumber" />,
                        navLink: "/components/data-entry/input-number",
                    },
                    {
                        id: "radio",
                        title: <IntlMessages id="sidebar-components-radio" />,
                        navLink: "/components/data-entry/radio",
                    },
                    {
                        id: "rate",
                        title: <IntlMessages id="sidebar-components-rate" />,
                        navLink: "/components/data-entry/rate",
                    },
                    {
                        id: "select",
                        title: <IntlMessages id="sidebar-components-select" />,
                        navLink: "/components/data-entry/select",
                    },
                    {
                        id: "slider",
                        title: <IntlMessages id="sidebar-components-slider" />,
                        navLink: "/components/data-entry/slider",
                    },
                    {
                        id: "switch",
                        title: <IntlMessages id="sidebar-components-switch" />,
                        navLink: "/components/data-entry/switch",
                    },
                    {
                        id: "upload",
                        title: <IntlMessages id="sidebar-components-upload" />,
                        navLink: "/components/data-entry/upload",
                    },
                ],
            },
            {
                id: "data-display",
                title: <IntlMessages id="sidebar-components-data-display" />,
                icon: <TicketStar set="curved" className="remix-icon" />,
                children: [
                    {
                        id: "avatar",
                        title: <IntlMessages id="sidebar-components-avatar" />,
                        navLink: "/components/data-display/avatar",
                    },
                    {
                        id: "badge",
                        title: <IntlMessages id="sidebar-components-badge" />,
                        navLink: "/components/data-display/badge",
                    },
                    {
                        id: "calendar",
                        title: <IntlMessages id="sidebar-components-calendar" />,
                        navLink: "/components/data-display/calendar",
                    },
                    {
                        id: "card",
                        title: <IntlMessages id="sidebar-components-card" />,
                        navLink: "/components/data-display/card",
                    },
                    {
                        id: "collapse",
                        title: <IntlMessages id="sidebar-components-collapse" />,
                        navLink: "/components/data-display/collapse",
                    },
                    {
                        id: "comment",
                        title: <IntlMessages id="sidebar-components-comment" />,
                        navLink: "/components/data-display/comment",
                    },
                    {
                        id: "empty",
                        title: <IntlMessages id="sidebar-components-empty" />,
                        navLink: "/components/data-display/empty",
                    },
                    {
                        id: "list",
                        title: <IntlMessages id="sidebar-components-list" />,
                        navLink: "/components/data-display/list",
                    },
                    {
                        id: "popover",
                        title: <IntlMessages id="sidebar-components-popover" />,
                        navLink: "/components/data-display/popover",
                    },
                    {
                        id: "table",
                        title: <IntlMessages id="sidebar-components-table" />,
                        navLink: "/components/data-display/table",
                    },
                    {
                        id: "tabs",
                        title: <IntlMessages id="sidebar-components-tabs" />,
                        navLink: "/components/data-display/tabs",
                    },
                    {
                        id: "tag",
                        title: <IntlMessages id="sidebar-components-tag" />,
                        navLink: "/components/data-display/tag",
                    },
                    {
                        id: "timeline",
                        title: <IntlMessages id="sidebar-components-timeline" />,
                        navLink: "/components/data-display/timeline",
                    },
                    {
                        id: "tooltip",
                        title: <IntlMessages id="sidebar-components-tooltip" />,
                        navLink: "/components/data-display/tooltip",
                    },
                ],
            },
            {
                id: "feedback",
                title: <IntlMessages id="sidebar-components-feedback" />,
                icon: <Danger set="curved" className="remix-icon" />,
                children: [
                    {
                        id: "alert",
                        title: <IntlMessages id="sidebar-components-alert" />,
                        navLink: "/components/feedback/alert",
                    },
                    {
                        id: "drawer",
                        title: <IntlMessages id="sidebar-components-drawer" />,
                        navLink: "/components/feedback/drawer",
                    },
                    {
                        id: "modal",
                        title: <IntlMessages id="sidebar-components-modal" />,
                        navLink: "/components/feedback/modal",
                    },
                    {
                        id: "message",
                        title: <IntlMessages id="sidebar-components-message" />,
                        navLink: "/components/feedback/message",
                    },
                    {
                        id: "notification",
                        title: <IntlMessages id="sidebar-components-notification" />,
                        navLink: "/components/feedback/notification",
                    },
                    {
                        id: "popconfirm",
                        title: <IntlMessages id="sidebar-components-popconfirm" />,
                        navLink: "/components/feedback/popconfirm",
                    },
                    {
                        id: "progress",
                        title: <IntlMessages id="sidebar-components-progress" />,
                        navLink: "/components/feedback/progress",
                    },
                    {
                        id: "result",
                        title: <IntlMessages id="sidebar-components-result" />,
                        navLink: "/components/feedback/result",
                    },
                    {
                        id: "skeleton",
                        title: <IntlMessages id="sidebar-components-skeleton" />,
                        navLink: "/components/feedback/skeleton",
                    },
                ],
            },
        ]
    },
];

export default components