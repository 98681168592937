import { Home, Graph, Document } from "react-iconly";
import IntlMessages from "../../layout/components/lang/IntlMessages";

const main = [
    {
        header: <IntlMessages id="sidebar-main" />,
    },
    {
        id: "dashboard",
        title: <IntlMessages id="sidebar-dashboards" />,
        icon: <Home set="curved" className="remix-icon" />,
        children: [
            {
                id: "analytics",
                title: <IntlMessages id="sidebar-dashboards-analytics" />,
                navLink: "/main/dashboard/analytics",
            },
            {
                id: "dashboard-eCommerce",
                title: <IntlMessages id="sidebar-dashboards-ecommerce" />,
                navLink: "/main/dashboard/ecommerce",
            },
            {
                id: "dashboard-nft",
                title: <IntlMessages id="sidebar-dashboards-nft" />,
                navLink: "/main/dashboard/nft",
            },
        ],
    },
    {
        id: "widgets",
        title: <IntlMessages id="sidebar-widgets" />,
        icon: <Graph set="curved" className="remix-icon" />,
        children: [
            {
                id: "cards",
                title: <IntlMessages id="sidebar-widgets-yoda-card" />,
                children: [
                    {
                        id: "advance",
                        title: <IntlMessages id="sidebar-widgets-advance" />,
                        navLink: "/main/widgets/cards/advance",
                    },
                    {
                        id: "statistics",
                        title: <IntlMessages id="sidebar-widgets-statistics" />,
                        navLink: "/main/widgets/cards/statistics",
                    },
                    {
                        id: "widgets-analytics",
                        title: <IntlMessages id="sidebar-widgets-analytics" />,
                        navLink: "/main/widgets/cards/analytics",
                    },
                ],
            },
            {
                id: "charts",
                title: <IntlMessages id="sidebar-widgets-charts" />,
                navLink: "/main/widgets/charts",
            },
            {
                id: "selectbox",
                title: <IntlMessages id="sidebar-widgets-selectbox" />,
                navLink: "/main/widgets/selectbox",
            },
            {
                id: "illustration-set",
                title: <IntlMessages id="sidebar-widgets-illustration" />,
                navLink: "/main/widgets/illustration-set",
            },
            {
                id: "crypto-icons",
                title: <IntlMessages id="sidebar-widgets-crypto-icons" />,
                navLink: "/main/widgets/crypto-icons",
            },
            {
                id: "user-icons",
                title: <IntlMessages id="sidebar-widgets-user-icons" />,
                navLink: "/main/widgets/user-icons",
            },
            {
                id: "flags",
                title: <IntlMessages id="sidebar-widgets-flags" />,
                navLink: "/main/widgets/flags",
            },
        ],
    },
    {
        id: "layouts",
        title: <IntlMessages id="sidebar-layouts" />,
        icon: <Document set="curved" className="remix-icon" />,
        children: [
            {
                id: "divider",
                title: <IntlMessages id="sidebar-layouts-divider" />,
                navLink: "/main/layouts/divider",
            },
            {
                id: "grid-system",
                title: <IntlMessages id="sidebar-layouts-grid-system" />,
                navLink: "/main/layouts/grid-system",
            },
            {
                id: "page-layouts",
                title: <IntlMessages id="sidebar-layouts-page" />,
                children: [
                    {
                        id: "boxed-layout",
                        title: <IntlMessages id="sidebar-layouts-page-boxed" />,
                        navLink: "/main/layouts/page-layouts/boxed-layout",
                    },
                    {
                        id: "vertical-layout",
                        title: <IntlMessages id="sidebar-layouts-page-vertical" />,
                        navLink: "/main/layouts/page-layouts/vertical-layout",
                    },
                    {
                        id: "horizontal-layout",
                        title: <IntlMessages id="sidebar-layouts-page-horizontal" />,
                        navLink: "/main/layouts/page-layouts/horizontal-layout",
                    },
                    {
                        id: "full-layout",
                        title: <IntlMessages id="sidebar-layouts-page-full" />,
                        navLink: "/main/layouts/page-layouts/full-layout",
                    },
                ],
            },
        ],
    },
];

export default main